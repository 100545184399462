import React, { useState } from 'react'

export const IsMobileContext = React.createContext()

const IsMobileProvider = ({ children }) => {
    const [isMobile, setIsMobile] = useState(undefined)

    return (
        <IsMobileContext.Provider value={{ isMobile: isMobile, setIsMobile: setIsMobile }}>
            {children}
        </IsMobileContext.Provider>
    )
}


export default IsMobileContext

export { IsMobileProvider }