import React from 'react'
import styled, { css } from 'styled-components'
import { GlobalStyles } from './src/styles/global'
import tw from 'tailwind.macro'
import Div100vh from 'react-div-100vh'
import Helmet from 'react-helmet'
import CartProvider from './src/components/Cart/CartProvider'
import { MobileMenuProvider } from './src/components/MobileMenu/context'
import { IsMobileProvider } from './src/context/IsMobileContext'

export default ({ element, props }) => {
    return (
        <CartProvider>
            <IsMobileProvider>
                <MobileMenuProvider>
                    <GlobalStyles />
                    {element}
                </MobileMenuProvider>
            </IsMobileProvider>
        </CartProvider>
    )
}