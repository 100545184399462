import { SHOPIFY_STOREFRONT_ACCESS_TOKEN, SHOPIFY_DOMAIN } from '../constants'
import Client from 'shopify-buy'

export const client = Client.buildClient({
    storefrontAccessToken: SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    domain: SHOPIFY_DOMAIN,
})

// Price number format

export const getNumberScaleProps = () => {
    return {
        displayType: 'text',
        thousandSeparator: true,
        fixedDecimalScale: true,
        decimalScale: 2,
    }
}

// get Price for cart item

export const getPriceProps = (price, exGST) => {
    return {
        value: exGST ? price.value : parseFloat(price.value) * 1.1,
        prefix: price.minPrice !== price.maxPrice ? 'From $' : '$',
    }
}