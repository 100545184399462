import { css } from 'styled-components';
import { useMedia } from 'react-use';

export const breakpoints = {
    des: 1280,
    tab: 1024,
    tabPor: 768,
    mob: 640,
}

const setMedia = size => (...args) => css`
    @media (max-width: ${size}px) {
        ${css(...args)}
    }
`

export const isClient = () => {
	if (typeof window !== 'undefined') {
		return true
	}

	return false;
}

const getMedia = (breakpoint) => () => {
    if (typeof navigator !== "undefined" && window && window.innerWidth <= breakpoints[breakpoint]) {
        return true
    }

    return false;
}

export const media = {
    des: setMedia(breakpoints.des),
    tab: setMedia(breakpoints.tab),
    tabPor: setMedia(breakpoints.tabPor),
    mob: setMedia(breakpoints.mob)
};


export const useBreakpoint = (breakpoint) => {
    return useMedia(`(max-width: ${breakpoints[breakpoint]}px)`)
}
