import { css } from 'styled-components'
import tw from 'tailwind.macro'
import { media } from './utils'

/*-----------------------------------*/
/* Global Type
/*-----------------------------------*/

const type = {
    body: css`
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25;
    `,
    heading: css`
        font-size: 2.3rem;
        font-weight: 400;
        line-height: 1;

        ${media.tabPor`
            font-size: 1.8rem;
        `}
    `,
    subheading: css`
        font-size: 2rem;
        font-weight: 400;
        line-height: 1;

        ${media.tabPor`
            font-size: 1.8rem;
        `}
    `,
    caption: css`
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.25;
    `,
    headerLinks: css`
        font-size: 2.3rem;
        font-weight: 400;
        line-height: 1;
    `
}

export default type
