import React, { useState } from 'react'
import { useToggle } from 'react-use'

export const MobileMenuContext = React.createContext()

const MobileMenuProvider = ({ children }) => {
    const [active, toggleActive] = useToggle(false)

    return (
        <MobileMenuContext.Provider value={{ active: active, toggleActive: toggleActive }}>
            {children}
        </MobileMenuContext.Provider>
    )
}

export default MobileMenuContext

export { MobileMenuProvider }