import { css, createGlobalStyle } from 'styled-components'
import { media } from './utils'
import tw from 'tailwind.macro'

import globalType from './type'
// Re-export type
export const type = globalType

export const maxWidth = 1680

export const hoverState = css`
    opacity: 1;
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const GlobalStyles = createGlobalStyle`
    /* base for rem units */
    html {
        font-size: 12px;
    }

    @media (min-width: 375px) {
        html {
            font-size: calc(12px + 4 * (100vw - 375px) / 1305);
        }
    }
    
    @media (min-width: ${maxWidth}px) {
        html {
            font-size: 16px;
        }
    }

    html,
    body {
        ${tw`m-0 p-0 w-full h-full font-body bg-white text-grey uppercase`};
        font-weight: normal;
        -webkit-font-smoothing: auto;
        letter-spacing: -0.04em;
        ${globalType.body}
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 1rem;
        margin: 0;
    }

    /* b, strong {
        font-weight: 700;
    } */

    ul {
        list-style: disc;
        padding-left: 2rem;
    }
 
    * {
        -webkit-overflow-scrolling: touch;
    }

    .tiny-mce {
        > * {
            &:not(:first-child) {
                margin-top: 1em;
            }   
        }
    }
`

export const padding = css`
    padding-left: 2rem;
    padding-right: 2rem;
    box-sizing: border-box;

    ${media.mob`
        padding-left: 1rem;
        padding-right: 1rem;
    `}
`

export const container = css`
    ${tw`w-full h-full mx-auto`};
    width: ${maxWidth}px;
    max-width: 100%;
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const button = css`
	padding: 4px 2rem;
	background-color: transparent;
	border-radius: 0;
    ${tw`border border-grey text-grey`};
    line-height: 1;
    cursor: pointer;
    transition: all 0.2s ease;

	display: flex;
	justify-content: center;
	align-items: center;
`